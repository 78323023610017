// This is example of menu item without group for horizontal layout. There will be no children.

// assets
import { IconDeviceAnalytics, IconBook2, IconSettings, IconLogout } from '@tabler/icons-react';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const icons = {
    IconDeviceAnalytics,
    IconBook2,
    IconSettings,
    IconLogout
};
const dashboard = {
    id: 'dashboard',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'subjects',
            title: 'Subjects',
            type: 'item',
            url: '/subjects',
            icon: icons.IconBook2,
            breadcrumbs: false
        },
        {
            id: 'account-settings',
            title: 'Account Settings',
            type: 'item',
            url: '/account-settings-page',
            icon: icons.IconSettings,
            breadcrumbs: false
        },
        {
            id: 'logout',
            title: 'Logout',
            type: 'item',
            icon: icons.IconLogout,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
