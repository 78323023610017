import { Grid, Typography, useMediaQuery, useTheme, Divider, Button } from '@mui/material';
import { gridSpacing } from 'store/constant';
import Chart from 'react-apexcharts';
import COLOURS from 'utils/colors';
import useConfig from 'hooks/useConfig';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useEffect, useRef, useState } from 'react';
import partnerLogo1 from 'assets/images/app-images/school.png';
import partnerLogo2 from 'assets/images/app-images/omotec_logo.png';
import useAuth from 'hooks/useAuth';
import axiosServices from 'utils/axios';
import { useParams } from 'react-router-dom';
import CircularLoader from 'ui-component/CircularLoader';

const ReportPage = () => {
    const { subjectId } = useParams();
    const { user } = useAuth();
    const graph1Ref = useRef();
    const { borderRadius } = useConfig();
    const theme = useTheme();
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const [subjectName, setSubjectName] = useState();
    const [academicYear, setAcademicYear] = useState();
    const [overallPercentage, setOverallPercentage] = useState(0);
    const [brainSkillData, setBrainSkillData] = useState();
    const [questionTypeData, setQuestionTypeData] = useState();
    const [difficultyLevelData, setDifficultyLevelData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [questionData, setQuestionData] = useState();
    let details = {
        overall: false,
        skills: false,
        questionType: false,
        difficultyLevel: false
    };

    useEffect(() => {
        resetAllDetails();
        getSubjectName();
        getAcademicYear();
        getQuestionDetails();
        getOverallProgress();
        getSkillCard();
        getQuestionType();
        getDifficultyLevel();
        getObservations();
    }, []);

    const resetAllDetails = () => {
        details = {
            overall: false,
            skills: false,
            questionType: false,
            difficultyLevel: false
        };
    };
    const allDetailsLoaded = () => {
        if (details.overall && details.skills && details.questionType && details.difficultyLevel) {
            setIsLoading(false);
        }
    };
    const getSubjectName = () => {
        const localStorageValue = JSON.parse(localStorage.getItem('preSelectedValuesInDashboardPage'));
        setSubjectName(localStorageValue.name);
    };
    const getAcademicYear = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth(); // January is 0, December is 11
        // If month is July or later, academic year starts in current year
        // Else, it starts in the previous year
        if (currentMonth >= 6) {
            // July is month 6
            setAcademicYear(`${currentYear}-${(currentYear + 1).toString().slice(-2)}`);
        } else {
            setAcademicYear(`${currentYear - 1}-${currentYear.toString().slice(-2)}`);
        }
    };
    const getQuestionDetails = async () => {
        const input = {
            studentId: user.id,
            subjectId
        };
        const response = await axiosServices.post(`/questions/details`, input);
        if (response.data) {
            console.log(response.data);
            setQuestionData(response.data);
        }
    };
    const getOverallProgress = async () => {
        setIsLoading(true);
        try {
            const response = await axiosServices.post(`/students/${user.id}/scores`, { subjectId: subjectId });
            const { marksObtained, totalMarks } = response.data;
            if (marksObtained != null && totalMarks != null) {
                const percentage = ((marksObtained / totalMarks) * 100).toFixed(2);
                setOverallPercentage(percentage);
            } else {
                setOverallPercentage(0);
            }
            details.overall = true;
        } catch (error) {
            console.log(error);
        } finally {
            allDetailsLoaded();
        }
    };
    const getSkillCard = async () => {
        let input = {
            categoryId: 1, //categoryId 1 is for brain skills
            subjectId: subjectId,
            chapterId: 0, //chapterId 0 is for all chapters
            assessmentId: 0 //assessmentId 0 is for all assessments
        };
        try {
            const response = await axiosServices.post(`/students/${user.id}/skills`, input);
            let skills = response.data.length ? formatResponse(response) : null;
            setBrainSkillData(skills);
            details.skills = true;
        } catch (error) {
            console.log(error);
            console.log('error');
        } finally {
            allDetailsLoaded();
        }
    };
    const getQuestionType = async () => {
        let input = {
            categoryId: 3, //categoryId 1 is for brain skills
            subjectId: subjectId,
            chapterId: 0, //chapterId 0 is for all chapters
            assessmentId: 0 //assessmentId 0 is for all assessments
        };
        try {
            const response = await axiosServices.post(`/students/${user.id}/skills`, input);
            let skills = response.data.length ? formatResponse(response) : null;
            setQuestionTypeData(skills);
            details.questionType = true;
        } catch (error) {
            console.log(error);
            console.log('error');
        } finally {
            allDetailsLoaded();
        }
    };
    const getDifficultyLevel = async () => {
        let input = {
            categoryId: 2, //categoryId 1 is for brain skills
            subjectId: subjectId,
            chapterId: 0, //chapterId 0 is for all chapters
            assessmentId: 0 //assessmentId 0 is for all assessments
        };
        try {
            const response = await axiosServices.post(`/students/${user.id}/skills`, input);
            let skills = response.data.length ? formatResponse(response) : null;
            setDifficultyLevelData(skills);
            details.difficultyLevel = true;
        } catch (error) {
            console.log(error);
            console.log('error');
        } finally {
            allDetailsLoaded();
        }
    };
    const getObservations = async () => {
        allDetailsLoaded();
    };

    const fillColor = Math.abs(overallPercentage) >= 75 ? COLOURS.GREEN : Math.abs(overallPercentage) >= 50 ? COLOURS.YELLOW : COLOURS.RED;
    var overallPerformanceChartData = {
        height: '380',
        type: 'radialBar',
        series: [Math.abs(overallPercentage)],
        options: {
            chart: {
                id: 'overall-performance-chart',
                events: {
                    mounted: (chart) => {
                        chart.windowResizeHandler();
                    }
                }
            },
            legend: {
                show: false
            },
            fill: {
                colors: [fillColor]
            },
            stroke: {
                show: true,
                lineCap: 'round'
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        showOn: 'always',
                        name: {
                            show: false
                        },
                        value: {
                            offsetY: 10,
                            show: true,
                            fontSize: '25px',
                            fontWeight: 'bold',
                            color: fillColor
                        }
                    }
                }
            }
        }
    };
    var skill1ChartData = {
        height: '340',
        type: 'bar',
        series: [
            {
                name: 'Score',
                data: brainSkillData
            }
        ],
        options: {
            colors: ['#f94144', '#277da1', '#f8961e', '#4d908e', '#2196f3', '#90be6d', '#f3722c', '#577590', '#43aa8b', '#eeef20'],
            chart: {
                id: 'skill-report-chart',
                toolbar: {
                    show: false
                },
                events: {
                    mounted: (chart) => {
                        chart.windowResizeHandler();
                    }
                }
            },
            legend: {
                show: false
            },
            dataLabels: {
                fontSize: '20px',
                enabled: matchDownSm ? false : true,
                formatter: function (val) {
                    return val !== 0 ? Math.round(val) + '%' : '';
                },
                offsetY: 0,
                style: {
                    fontSize: '16px',
                    colors: ['#fff']
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '35%',
                    distributed: true,
                    borderRadius: borderRadius,
                    borderRadiusApplication: 'end',
                    dataLabels: {
                        position: 'center' // top, center, bottom
                    }
                }
            },
            yaxis: {
                max: 100,
                tickAmount: 5,
                labels: {
                    style: {
                        fontSize: '16px',
                        fontWeight: 'bold'
                    }
                }
            },
            xaxis: {
                labels: {
                    style: {
                        fontSize: '16px',
                        fontWeight: 'bold'
                    }
                }
            }
        }
    };
    var skill2ChartData = {
        height: '340',
        type: 'bar',
        series: [
            {
                name: 'Score',
                data: difficultyLevelData
            }
        ],
        options: {
            colors: ['#f94144', '#277da1', '#f8961e', '#4d908e', '#2196f3', '#90be6d', '#f3722c', '#577590', '#43aa8b', '#eeef20'],
            chart: {
                id: 'skill-report-chart',
                toolbar: {
                    show: false
                },
                events: {
                    mounted: (chart) => {
                        chart.windowResizeHandler();
                    }
                }
            },
            legend: {
                show: false
            },
            dataLabels: {
                fontSize: '20px',
                enabled: matchDownSm ? false : true,
                formatter: function (val) {
                    return val !== 0 ? Math.round(val) + '%' : '';
                },
                offsetY: 0,
                style: {
                    fontSize: '16px',
                    colors: ['#fff']
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '35%',
                    distributed: true,
                    borderRadius: borderRadius,
                    borderRadiusApplication: 'end',
                    dataLabels: {
                        position: 'center' // top, center, bottom
                    }
                }
            },
            yaxis: {
                max: 100,
                tickAmount: 5,
                labels: {
                    style: {
                        fontSize: '16px',
                        fontWeight: 'bold'
                    }
                }
            },
            xaxis: {
                labels: {
                    style: {
                        fontSize: '16px',
                        fontWeight: 'bold'
                    }
                }
            }
        }
    };
    var skill3ChartData = {
        height: '340',
        type: 'bar',
        series: [
            {
                name: 'Score',
                data: questionTypeData
            }
        ],
        options: {
            colors: ['#f94144', '#277da1', '#f8961e', '#4d908e', '#2196f3', '#90be6d', '#f3722c', '#577590', '#43aa8b', '#eeef20'],
            chart: {
                id: 'skill-report-chart',
                toolbar: {
                    show: false
                },
                events: {
                    mounted: (chart) => {
                        chart.windowResizeHandler();
                    }
                }
            },
            legend: {
                show: false
            },
            dataLabels: {
                fontSize: '20px',
                enabled: matchDownSm ? false : true,
                formatter: function (val) {
                    return val !== 0 ? Math.round(val) + '%' : '';
                },
                offsetY: 0,
                style: {
                    fontSize: '16px',
                    colors: ['#fff']
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '35%',
                    distributed: true,
                    borderRadius: borderRadius,
                    borderRadiusApplication: 'end',
                    dataLabels: {
                        position: 'center' // top, center, bottom
                    }
                }
            },
            yaxis: {
                max: 100,
                tickAmount: 5,
                labels: {
                    style: {
                        fontSize: '16px',
                        fontWeight: 'bold'
                    }
                }
            },
            xaxis: {
                labels: {
                    style: {
                        fontSize: '16px',
                        fontWeight: 'bold'
                    }
                }
            }
        }
    };

    const formatResponse = (response) => {
        let returnValue = [];
        response.data?.forEach((element) => {
            returnValue.push({
                x: element.Tag.name,
                y: ((element.marksObtained / element.totalMarks) * 100).toFixed(1)
            });
        });
        return returnValue;
    };

    const downloadPDF = async () => {
        const pdf = new jsPDF('p', 'mm', 'a4', true);
        const graph1Canvas = await html2canvas(graph1Ref.current);
        const graph1Img = graph1Canvas.toDataURL('image/png');
        let pageWidth = pdf.internal.pageSize.getWidth();
        pdf.addImage(graph1Img, 'PNG', 0, 0, pageWidth, 0);
        pdf.addImage(partnerLogo1, 'PNG', 10, 3, 0, 20);
        pdf.addImage(partnerLogo2, 'PNG', 180, 3, 0, 20);
        pdf.save('Skill Card.pdf');
    };

    return (
        <>
            {isLoading ? (
                <>
                    <CircularLoader />
                </>
            ) : (
                <>
                    <Button onClick={downloadPDF}>Download</Button>
                    <Grid container sx={{ background: '#fff' }} p={4} ref={graph1Ref}>
                        <Grid item xs={12}>
                            <Grid container spacing={gridSpacing} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
                                <Grid item xs={9}>
                                    <Typography variant="h1" m={2}>
                                        {user.Partner ? user?.Partner.name : 'FindInbox Skill Card'}
                                    </Typography>
                                    <Typography variant="h2" m={2}>
                                        Skill Card for the Academic Year {academicYear} | Powered by{' '}
                                        <span style={{ color: '#4a79c9' }}>&copy; www.findinbox.com</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                mt={2}
                                container
                                justifyContent={'space-around'}
                                alignItems={'center'}
                                sx={{ background: '#0e2245' }}
                                borderRadius={'5px'}
                                flexGrow={1}
                            >
                                <Grid item p={2}>
                                    <Typography variant="h2" color={'white'} textAlign={'start'}>
                                        Name: {user.name}
                                    </Typography>
                                </Grid>
                                <Grid item p={2}>
                                    <Typography variant="h2" color={'white'} textAlign={'start'}>
                                        Subject: {subjectName}
                                    </Typography>
                                </Grid>
                                <Grid item p={2}>
                                    <Typography variant="h2" color={'white'} textAlign={'end'}>
                                        Studying: {user?.Branch?.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent={'space-between'} alignItems={'center'} borderRadius={'5px'} mt={2} px={2}>
                                <Grid item>
                                    <Typography variant="h3" color={'#0e2245'}>
                                        Skill Questions Attempted: {questionData?.answered}/{questionData?.total}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h3" color={'#0e2245'}>
                                        USN/Reg No: {user.registerNumber}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container mt={2} justifyContent={'center'} spacing={gridSpacing}>
                                <Grid item xs={4} textAlign={'center'}>
                                    <Typography variant="h2">Overall Progress</Typography>
                                    <Chart {...overallPerformanceChartData} />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent={'center'} spacing={gridSpacing}>
                                <Grid item xs={12} textAlign={'center'}>
                                    <Typography variant="h2">Skill Card - Brain Skills</Typography>
                                    <Chart {...skill1ChartData} />
                                </Grid>
                            </Grid>
                            <Divider sx={{ borderColor: 'dark' }} />
                            <Grid container mt={2} justifyContent={'center'} spacing={gridSpacing}>
                                <Grid item xs={12} textAlign={'center'}>
                                    <Typography variant="h2">Performance - Question Type</Typography>
                                    <Chart {...skill3ChartData} />
                                </Grid>
                            </Grid>
                            <Divider sx={{ borderColor: 'dark' }} />
                            <Grid container mt={2} justifyContent={'center'} spacing={gridSpacing}>
                                <Grid item xs={6} textAlign={'center'}>
                                    <Typography variant="h2">Performance - Difficulty Level</Typography>
                                    <Chart {...skill2ChartData} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h2" sx={{ textAlign: 'center' }}>
                                        Observations
                                    </Typography>
                                    <ol style={{ listStyle: 'disc', fontSize: '20px', lineHeight: '25px' }}>
                                        <li style={{ padding: '8px' }}>Your current score is 36.92%, showing potential for improvement.</li>
                                        <li style={{ padding: '8px' }}>
                                            Strengths are visible in remembering (50%) and understanding (53%).
                                        </li>
                                        <li style={{ padding: '8px' }}>
                                            Applying knowledge (17%) requires more attention for better outcomes.
                                        </li>
                                        <li style={{ padding: '8px' }}>
                                            Enhancing problem-solving (31%) and logical thinking (30%) will further boost your performance.
                                        </li>
                                        <li style={{ padding: '8px' }}>
                                            Enhancing problem-solving (31%) and logical thinking (30%) will further boost your performance.
                                        </li>
                                    </ol>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default ReportPage;
