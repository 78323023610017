import { ArrowBack } from '@mui/icons-material';
import { IconButton, Grid, Typography } from '@mui/material';
import useConfig from 'hooks/useConfig';
const PropTypes = require('prop-types');

const PageHeader = ({ title, hasBackButton = false, actionComponent }) => {
    const { borderRadius } = useConfig();
    return (
        <>
            <Grid
                container
                style={{ background: '#fff', borderRadius: borderRadius }}
                px={hasBackButton ? 0.5 : 2.5}
                mb={2.5}
                minHeight={50}
                borderRadius={borderRadius}
            >
                <Grid container display={'flex'} justifyContent={'space-between'}>
                    <Grid item display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        {hasBackButton && (
                            <Grid item>
                                <IconButton
                                    color="primary"
                                    size="small"
                                    style={{ marginRight: '5px' }}
                                    onClick={() => {
                                        window.history.back();
                                    }}
                                >
                                    <ArrowBack />
                                </IconButton>
                            </Grid>
                        )}
                        <Grid item>
                            <Typography variant="h4">{title}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        {actionComponent}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

PageHeader.propTypes = {
    title: PropTypes.string,
    hasBackButton: PropTypes.bool,
    actionComponent: PropTypes.element
};

export default PageHeader;
